






import Vue from 'vue';

export default Vue.extend({
  name: 'RatesListPage',
  metaInfo() {
    return {
      title: 'Home - Our Nation Express',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Welome to Our Nation Express Home page.'
        }
      ]
    };
  }
});
